body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Plugins to add to css */
.gradient_image_header_1 {
  background: rgb(251, 144, 123);
  background: linear-gradient(
    315deg,
    rgba(251, 144, 123, 1) 0%,
    rgba(241, 49, 190, 1) 100%
  );
}
.gradient_image_header_2 {
  background: rgb(157, 143, 227);
  background: linear-gradient(
    315deg,
    rgba(157, 143, 227, 1) 0%,
    rgba(203, 161, 230, 1) 100%
  );
}
.gradient_image_header_3 {
  background: rgb(78, 153, 186);
  background: linear-gradient(
    315deg,
    rgba(78, 153, 186, 1) 0%,
    rgba(96, 229, 211, 1) 100%
  );
}
.cascade-card-overlay > div {
  border-radius: 4px;
}
.ck.ck-toolbar.ck-toolbar_grouping {
  background-color: var(--mdc-theme-surface) !important;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  background-color: var(--mdc-theme-surface) !important;
}
.ck.ck-button.ck-off {
  color: rgba(var(--mdc-theme-on-surface), 0.9) !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: rgba(var(--mdc-theme-on-surface), 0.2) !important;
}
.ck.ck-reset.ck-list {
  background-color: var(--mdc-theme-surface) !important;
}
.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters
  span,
.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters
  div,
.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters i {
  color: rgba(var(--mdc-theme-on-surface), 0.87) !important;
}
.MuiInputAdornment-root.MuiInputAdornment-positionStart
  input::-webkit-input-placeholder,
.MuiInputAdornment-root.MuiInputAdornment-positionStart span,
.MuiIconButton-label {
  color: rgba(var(--mdc-theme-on-surface), 0.87) !important;
}
.MuiTableCell-root.MuiTableCell-head {
  background-color: var(--mdc-theme-surface) !important;
}
