:root {
  --mdc-theme-primary: #6200ee;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-secondary: #ff4081;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-surface: #242526;
  --mdc-theme-on-surface: 256, 256, 256;
  --mdc-theme-background: #121212;
  --mdc-theme-app-bar: #2c2c2c;
  --mdc-theme-on-app-bar: #fff;
}
.MuiPaper-root {
  background-color: var(--mdc-theme-surface) !important;
}
.MTableHeader-header-13 {
  background-color: var(--mdc-theme-surface) !important;
}
.MuiTableCell-head {
  color: rgba(var(--mdc-theme-on-surface), 0.87) !important;
}
.MuiTableSortLabel-root:hover {
  color: rgba(var(--mdc-theme-on-surface), 0.54) !important;
}
.MuiTableCell-body {
  color: rgba(var(--mdc-theme-on-surface), 0.87) !important;
}
.uk-section {
  background-color: /* #f8f9fa; */#121212;
}
.uk-section h1,
.uk-section h2,
.uk-section h3,
.uk-section h4,
.uk-section h5 {
  color: rgba(var(--mdc-theme-on-surface), 0.87) !important;
}
.uk-table-striped tbody tr:nth-of-type(odd), .uk-table-striped>tr:nth-of-type(odd){
  border-top: 1px solid rgba(var(--mdc-theme-on-surface), 0.1);
}
@font-face {
  font-family: "Bungee";
  src: url("./assets/fonts/Bungee-Regular.otf");
}
@font-face {
  font-family: "WoodfordBourne";
  src: url("./assets/fonts/WoodfordBournePRO-Regular.otf");
}
.landing-banner {
  height: 80px !important;
  backdrop-filter: blur(1.5rem);
  background: rgba(36, 37, 38, 0.8) !important;
}
body {
  font: 400 17px / 1.42857143 "WoodfordBourne", sans-serif;
}
.md-btn {
  font-family: "WoodfordBourne", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bungee", sans-serif;
}
h4,
h5,
h6 {
  font-family: "Bungee", sans-serif;
  font-weight: 200;
}
.uk-navbar-nav > li > a {
  font: 400 14px / 1.42857143 "Bungee", sans-serif;
}
.purple-text {
  color: #9753e5;
}
.purple-bg {
  background-color: #9753e5;
}
.blue-text {
  color: #1b4de2;
}
.blue-bg {
  background-color: #1b4de2;
}
:root {
  --mdc-theme-primary: #1b4de2;
  --mdc-theme-primary-light: #c5cae9;
  --mdc-theme-secondary: #9753e5;
}

.about_section,
.about_section > .uk-container > .uk-grid {
  position: relative;
}
.about_section:before {
  position: absolute;
  content: "";
  top: -20px;
  left: 0;
  background-color: #1b4de2;
  width: 36%;
  height: calc(100% - -20px);
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;

  background: #fff;
  border: 5px solid #1b4de2;
  color: #1b4de2;
  text-align: center;
}
.swiper-container {
  height: 400px;
}
.slide-gym {
  background-size: cover;
  background-position: center;
}
.datatable-header {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
  justify-content: flex-start;
}
.datatable-row {
  white-space: nowrap;
}
.datatable-default-order-icon {
  opacity: 0;
}

.datatable-header:hover > .datatable-default-order-icon {
  opacity: 0.5;
}
.price_match {
  text-decoration: line-through;
}
/* html {
  background-color: #f6f7f9;
} */
.selected_card {
  border: 2px solid #9753e5;
}

/* Bugs in last version */
/* .uk-modal {
  z-index: 1010 !important;
}
.md-input-wrapper.md-select::after {
  position: absolute;
  top: 14px;
  right: 7px;
  width: 18px;
  height: 24px;
  content: "\e313";
  font-family: "Material Icons";
  font-size: 18px;
  display: block;
  color: #727272;
  -webkit-transition: -webkit-transform 280ms;
  transition: -webkit-transform 280ms;
  transition: transform 280ms;
  transition: transform 280ms, -webkit-transform 280ms;
}

.md-input-wrapper.md-select.md-input-no-label.md-input-dense::after {
  top: 8px;
}
.uk-table.uk-table-hover tbody tr:hover,
.uk-table.uk-table-hover > tr:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.uk-table.uk-table-dense td,
.uk-table.uk-table-dense th {
  padding: 6px 16px 6px 16px !important;
  height: 48px;
}
.md-input-wrapper-with-icon > select {
  padding-left: 48px !important;
  padding-right: 15px !important;
}
#admin-header > .uk-navbar-container > .uk-navbar-right {
  padding-right: 5px;
} */

.md-drawer_content {
  height: 100vh;
}
.uk-table td {
  height: 52px;
}
